"use client";

import { createTheme, SxProps } from "@mui/material/styles";
import colors from "./palette";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    h1XL: SxProps;
  }

  interface BreakpointOverrides {
    xxl: true;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1XL?: SxProps;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h1XL: true;
  }
}

const baseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 900,
      lg: 1200,
      xl: 1600,
      xxl: 1920,
    },
  },
});

const theme = createTheme({
  ...baseTheme,

  cssVariables: true,

  typography: {
    fontFamily: "var(--font-dm-sans)",
    h1XL: {
      fontSize: "40px",
      fontWeight: 700,
      lineHeight: "110%",
      letterSpacing: "-0.02em",
      fontFamily: "var(--font-dm-sans)",

      [baseTheme.breakpoints.up("sm")]: {
        fontSize: "42px",
      },

      [baseTheme.breakpoints.up("md")]: {
        fontSize: "46px",
      },
      [baseTheme.breakpoints.up("lg")]: {
        fontSize: "48px",
      },
      [baseTheme.breakpoints.up("xl")]: {
        fontSize: "72px",
        lineHeight: "100%",
        letterSpacing: "-0.05em",
      },
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*::-webkit-scrollbar": {
          marginLeft: "8px",
          width: "4px",
          borderRadius: "8px",
          backgroundColor: colors.black100,
        },
        "*::-webkit-scrollbar-thumb": {
          width: "4px",
          borderRadius: "8px",
          backgroundColor: colors.black400,
        },
        "*::-webkit-scrollbar-thumb:focus": {
          backgroundColor: colors.black400,
        },
        "*::-webkit-scrollbar-thumb:active": {
          backgroundColor: colors.black400,
        },
        "*::-webkit-scrollbar-thumb:hover": {
          backgroundColor: colors.black400,
        },
        "*::-webkit-scrollbar-corner": {
          backgroundColor: colors.black400,
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.black,
        },
        h1: {
          fontSize: "32px",
          fontWeight: 700,
          lineHeight: "110%",
          letterSpacing: "-0.02em",

          [baseTheme.breakpoints.up("sm")]: {
            fontSize: "34px",
          },
          [baseTheme.breakpoints.up("md")]: {
            fontSize: "36px",
          },
          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "40px",
          },
          [baseTheme.breakpoints.up("xl")]: {
            fontSize: "64px",
            lineHeight: "105%",
            letterSpacing: "-0.05em",
          },
        },
        h2: {
          fontSize: "22px",
          fontWeight: 700,
          lineHeight: "110%",
          letterSpacing: "-0.03em",

          [baseTheme.breakpoints.up("sm")]: {
            fontSize: "24px",
          },
          [baseTheme.breakpoints.up("md")]: {
            fontSize: "26px",
          },
          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "30px",
          },
          [baseTheme.breakpoints.up("xl")]: {
            fontSize: "40px",
            lineHeight: "105%",
            letterSpacing: "-0.05em",
          },
        },
        h3: {
          fontSize: "22px",
          fontWeight: 700,
          lineHeight: "120%",
          letterSpacing: "-0.04em",

          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "24px",
          },
          [baseTheme.breakpoints.up("xl")]: {
            fontSize: "26px",
            lineHeight: "130%",
          },
        },

        h4: {
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "115%",
          letterSpacing: "-0.03em",

          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "18px",
          },
          [baseTheme.breakpoints.up("xl")]: {
            fontSize: "20px",
            lineHeight: "130%",
            letterSpacing: "-0.04em",
          },
        },

        body1: {
          //Paragraph XL
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "130%",
          letterSpacing: "-0.02em",

          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "18px",
          },
          [baseTheme.breakpoints.up("xl")]: {
            fontSize: "20px",
            lineHeight: "140%",
          },
        },

        body2: {
          //Paragraph L
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "130%",
          letterSpacing: "-0.02em",

          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "16px",
          },
          [baseTheme.breakpoints.up("xl")]: {
            fontSize: "18px",
            lineHeight: "140%",
          },
        },

        subtitle1: {
          //Paragraph M
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "150%",
          letterSpacing: "-0.03em",

          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "15px",
          },
          [baseTheme.breakpoints.up("xl")]: {
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "130%",
            letterSpacing: "-0.02em",
          },
        },

        subtitle2: {
          //Tag Text
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "150%",
          letterSpacing: "-0.03em",

          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "14px",
          },
          [baseTheme.breakpoints.up("xl")]: {
            fontSize: "16px",
            lineHeight: "130%",
            letterSpacing: "-0.02em",
          },
        },

        button: {
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "100%",
          letterSpacing: "-0.02em",

          [baseTheme.breakpoints.up("lg")]: {
            fontSize: "16px",
          },
          [baseTheme.breakpoints.up("xl")]: {
            fontSize: "20px",

            letterSpacing: "-0.04em",
          },
        },

        caption: {
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "150%",
          letterSpacing: "-0.03em",

          [baseTheme.breakpoints.up("md")]: {
            fontSize: "14px",
            lineHeight: "130%",
            letterSpacing: "-0.02em",
          },
        },
      },
    },

    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: "14px",
          fontWeight: 400,
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: colors.blue,
          },

          "&.MuiCheckbox-indeterminate": {
            color: colors.blue,
          },
        },
      },
    },

    MuiSkeleton: {
      styleOverrides: {
        rounded: {
          borderRadius: "8px",
        },
      },
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "1468px !important",
        },
        maxWidthXl: {
          maxWidth: "1468px",
        },
        maxWidthXs: {
          maxWidth: "1468px",
        },
        maxWidthSm: {
          maxWidth: "1468px",
        },
        maxWidthMd: {
          maxWidth: "1468px",
        },
        maxWidthLg: {
          width: "100%",
          maxWidth: "1468px",
          [baseTheme.breakpoints.up("sm")]: {
            width: "100%",
          },
          [baseTheme.breakpoints.up("md")]: {
            width: "95%",
          },
          [baseTheme.breakpoints.up("lg")]: {
            width: "95%",
          },
          [baseTheme.breakpoints.up("xl")]: {
            width: "95%",
          },
        },
      },
    },

    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          color: colors.black500,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          // fontFamily: dmSans.style.fontFamily,
          borderColor: "rgba(155, 168, 183, 1)",
          "::placeholder": {
            color: "rgba(85, 98, 130, 1)",
          },
        },
      },
    },

    MuiSlider: {
      styleOverrides: {
        markLabel: {
          color: colors.black500,
          fontSize: "10px",
        },

        markLabelActive: {
          color: colors.blue,
        },

        thumb: {
          backgroundColor: colors.blue,
        },
        track: {
          backgroundColor: colors.blue,
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          // "&.Mui-expanded": {
          //   margin: 0,
          // },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          border: "1px solid",
          borderColor: colors.black400,

          "&:hover": {
            borderColor: "rgba(155, 168, 183, 1)",
          },

          "&.Mui-focused": {
            borderColor: colors.blue40,
          },

          "&.Mui-disabled": {
            color: colors.black500,
            backgroundColor: `${colors.black300} !important`,
            borderColor: colors.black100,
          },

          "& .MuiSelect-select": {
            fontSize: "18px",
            color: colors.black700,
          },

          "& fieldset": {
            display: "none",
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: "100%",
          fontSize: "18px",
          overflow: "hidden",
          borderRadius: 8,
          backgroundColor: colors.white,
          border: "1px solid",
          borderColor: colors.black400,
          color: colors.black700,

          transition: baseTheme.transitions.create([
            "border-color",
            "background-color",
            "box-shadow",
          ]),

          "& input": {
            boxSizing: "content-box !important",
            padding: "16px 12px !important",
          },
          "& input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #fff inset",
            WebkitTextFillColor: colors.black700,
            caretColor: colors.black700,
          },
          "&:hover": {
            backgroundColor: "#fff",
            borderColor: "rgba(155, 168, 183, 1)",
          },
          "&:before": {
            content: "none",
          },
          "&:after": {
            content: "none",
          },
          "&.Mui-focused": {
            backgroundColor: "#fff",
            borderColor: colors.blue40,
          },

          "&.Mui-disabled": {
            color: colors.black500,
            backgroundColor: `${colors.black300} !important`,
            borderColor: colors.black100,
          },

          "& .MuiOutlinedInput-notchedOutline": {
            display: "none",
          },

          "& .MuiFormLabel-root": {
            color: colors.black700,

            fontSize: "18px",

            "&.Mui-focused": {
              color: colors.black500,
            },

            "&.Mui-disabled": {
              color: colors.black500,
            },
          },
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
          fontSize: "18px",

          "& .MuiFilledInput-root": {
            overflow: "hidden",
            borderRadius: 8,
            backgroundColor: colors.white,
            border: "1px solid",
            fontSize: "18px",
            borderColor: colors.black400,
            color: colors.black700,

            transition: baseTheme.transitions.create([
              "border-color",
              "background-color",
              "box-shadow",
            ]),

            "& input": {
              boxSizing: "content-box !important",
              padding: "16px 12px !important",
            },
            "& input:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 100px #fff inset",
              WebkitTextFillColor: colors.black700,
              caretColor: colors.black700,
            },
            "&:hover": {
              backgroundColor: "#fff",
              borderColor: "rgba(155, 168, 183, 1)",
            },
            "&:before": {
              content: "none",
            },
            "&:after": {
              content: "none",
            },
            "&.Mui-focused": {
              backgroundColor: "#fff",
              borderColor: colors.blue40,
            },

            "&.Mui-disabled": {
              color: colors.black500,
              backgroundColor: `${colors.black300} !important`,
              borderColor: colors.black100,
            },
          },
          "& .MuiFormLabel-root": {
            color: colors.black700,

            fontSize: "18px",

            "&.Mui-focused": {
              color: colors.black500,
            },

            "&.Mui-disabled": {
              color: colors.black500,
            },
          },
        },
      },
    },
  },
});

export default theme;
